<template>
    <div>
        <page-title :heading="$t('settings.lang_nav_design') " show-previous-button :subheading="$t('settings.lang_nav_design')" :icon=icon></page-title>

        <POSGridEditor :template-i-d="parseInt(id)"></POSGridEditor>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle";
    import POSGridEditor from "../../../components/settings/cashier/layout/POSGridEditor";

    export default {
        name: "LayoutPOSGridTemplate",

        components: {
            PageTitle,
            POSGridEditor
        },

        props: ["id"],

        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>